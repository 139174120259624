import React, { useState, useEffect, Fragment } from 'react';
import summary from '../data/contactSummary.json';
import { useSelector, useDispatch } from 'react-redux';
import { Link, InfoTooltip } from './controls';
import { useTranslate } from '../core/contexts';
import { selectSchedulePickup, selectPackages, selectSelectionsInfo, selectShipmentId } from '../core/redux/selectors';
import { updateShipmentPackages } from '../core/redux/actions';
import { getCarrierProfile } from '../services/carrierListService';
import { WEIGHT_UNITS, LENGTH_UNITS } from '../core/constants';
import { COLOR } from '../core/constants/Colors';
import { POWRIcon } from './styledComponents';
import { useConfigParams } from '../core/hooks';

const SuccessReturn = () => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { getContactUsLink, getRMAPackagingLink } = useConfigParams();
    const [reverse, setReverse] = useState(false);
    const [order, setOrder] = useState(0);
    const [carrierProfile, setCarrierProfile] = useState({});

    const savedSchedule = useSelector(selectSchedulePickup); // Saved info from setPickupLocation Step
    const packages = useSelector(selectPackages);
    const shipmentId = useSelector(selectShipmentId);
    const selectionsInfo = useSelector(selectSelectionsInfo); // Selected entities from SetPickupLocation Step

    const contactUsLink = getContactUsLink();
    const rmaPackagingLink = getRMAPackagingLink();

    useEffect(() => {
        if (shipmentId) {
            dispatch(updateShipmentPackages(shipmentId));
            let data = {
                _dc: Date.now(),
                ship_id: shipmentId
            }

            getCarrierProfile(data).then((response) => {
                if (response && response.res && response.res.dtos) {
                    setCarrierProfile(response.res.dtos[0]);
                }
            });
        }
    }, [shipmentId, dispatch]);

    const handleOrder = (o, value) => {
        if (order === o) {
            setReverse(!reverse);
        } else {
            setReverse(true);
        }
        setOrder(o);

        let r = order === o ? !reverse : true;
        const compare = (a, b) => {
            let av = a[value] ? typeof (a[value]) === 'object' ? a[value][0] : a[value] : '0';
            let bv = b[value] ? typeof (b[value]) === 'object' ? b[value][0] : b[value] : '0';
            if (av < bv) {
                return r ? -1 : 1;
            }
            if (av > bv) {
                return r ? 1 : -1;
            }
            return 0;
        }

        summary.packages.sort(compare);
    }

    const columns = [
        { id: 0, value: 'rma', text: translate('title.rma') },
        { id: 1, value: 'package', text: translate('title.package') },
        { id: 2, value: 'tracking', text: translate('gridColumns.tracking') },
        { id: 3, value: 'weight', text: translate('title.weight') },
        { id: 4, value: 'size', text: translate('gridColumns.dimensions') },
        { id: 5, value: 'productQty', text: translate('gridColumns.qty') }
    ];

    return (<>
        <div className="successReturnContainer">
            <div className="successCardBody">
                <div className="successMessage">
                    {carrierProfile && carrierProfile.carrier_error_message ?
                        <Fragment>
                            <div className="text-center maxWidth" key={`success-${carrierProfile && carrierProfile.carrier_error_message}`}>
                                <POWRIcon className="fas fa-exclamation-circle mb-4" color={COLOR.RED} size="56px" />
                                <div className="exclamationCircle text-center">
                                    <p>{translate('title.shipmentDenied')} "{carrierProfile.carrier_service_descr}"</p>
                                    <p>{carrierProfile.carrier_error_message}</p>
                                </div>
                            </div>

                            <p className="m-0">{translate('message.errorSuccessScreen1')} <a className="link" target={"_blank"} rel="noopener noreferrer" href={contactUsLink}>{translate('message.contactAgent')}</a> {translate('message.errorSuccessScreen2')}</p>
                        </Fragment>
                        : carrierProfile && carrierProfile.courier_dispatch_error ?
                            <Fragment>
                                <div className="text-center maxWidth" key={`success-${carrierProfile && carrierProfile.courier_dispatch_error}`}>
                                    <POWRIcon className="fas fa-exclamation-circle mb-4" color={COLOR.YELLOW} size="56px" />
                                    <div className="exclamationRectangle">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className="mr-2">{`${translate("message.successDenied")} `}</p>
                                            <InfoTooltip color={COLOR.WHITE} spanClass='big-tooltip'>
                                                {carrierProfile.courier_dispatch_error}
                                            </InfoTooltip>
                                        </div>
                                        <div className="d-flex">
                                            <p>{`${translate("message.contactCarrier")}`}
                                                <a
                                                    className="link"
                                                    target={"_blank"}
                                                    rel="noopener noreferrer"
                                                    href={contactUsLink}>
                                                    {translate("title.assetRecoveryTeam")}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p className="m-0">{carrierProfile.carrier_service_descr?.includes('(RS3)')  ? translate('message.successDescriptionUPS1') : translate('message.successDescription1')}</p> 
                                <p>{carrierProfile.carrier_service_descr?.includes('(RS3)') ? translate('message.successDescriptionUPS2'): translate('message.successDescription2')}</p>
                            </Fragment>
                            : carrierProfile ?
                                <Fragment>
                                    <div className="text-center maxWidth" key={`success-${carrierProfile && (carrierProfile.carrier_error_message || carrierProfile.courier_dispatch_error)}`}>
                                        <POWRIcon className="fas fa-check-circle mb-4" color={COLOR.GREEN} size="56px" />
                                        <h3>{translate("message.successReturnRequest")}</h3>
                                    </div>
                                    {savedSchedule && savedSchedule.instruct && savedSchedule.paperless ? 
                                        <p>{translate('message.useElp')}</p>    
                                    :
                                        <Fragment>
                                            <p className="m-0">{carrierProfile.carrier_service_descr?.includes('(RS3)') ? translate('message.successDescriptionUPS1') : translate('message.successDescription1')}</p> 
                                            <p>{carrierProfile.carrier_service_descr?.includes('(RS3)') ? translate('message.successDescriptionUPS2') : translate('message.successDescription2')}</p>
                                        </Fragment>
                                    }
                                </Fragment>
                                : null
                    }

                </div>
                <div className="successCardInfo w-100">
                    <div className="row infoRequest w-100">
                        <div className="col-12 col-lg-auto pt-2 pt-lg-0 infoBlock pr-3 mr-5">
                            <p><strong>{translate('gridColumns.carrier')}:</strong></p>
                            <p>{savedSchedule.carrier.descr || '—'}</p>
                        </div>
                        <div className="col-12 col-lg-auto pt-2 pt-lg-0 infoBlock pr-3 mr-5">
                            <p><strong>{translate('gridColumns.shipmentID')}:</strong></p>
                            <p>{`${selectionsInfo ? selectionsInfo.pickup.country : '—'}${shipmentId || '—'}`}</p>
                        </div>
                        <div className="col-12 col-lg-auto pt-2 pt-lg-0 infoBlock">
                            <p><strong>{translate('message.courierDispatch')}:</strong></p>
                            <p>{`${carrierProfile && carrierProfile.confirmation_number ? carrierProfile.confirmation_number : '—'}`}</p>
                        </div>
                        <div className="col-12 col-lg pt-2 pt-lg-0 text-right">
                            <Link onClick={() => window.open(rmaPackagingLink, "_blank")} to="">
                                <POWRIcon className="fas fa-info-circle mr-2" color={COLOR.CISCO_BLUE} size="14px" />
                                {translate('title.labelingInstructions')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h2 className="pt-2 pt-lg-3" >{translate('gridColumns.package')}</h2>
        <div className="successReturnContainer pt-0 pb-3">
            <div className="successCardBody">
                <table className="d-none d-xl-table">
                    <thead><tr className="packagesTitles">
                        {columns.map((c, i) => {
                            return (
                                <th
                                    className="packagesTH"
                                    onClick={handleOrder.bind(this, i, c.value)}
                                    key={`column-${i}-${reverse}-of-${order}`}
                                >
                                    <div className={`${c.id === 4 ? 'packagesTitle packagesNoWrap' : 'packagesTitle'} `}>
                                        {c.text}
                                        {c.id !== 4 &&
                                            <POWRIcon
                                                className={order === i && reverse ? "fas fa-arrow-up ml-2" : "fal fa-arrow-down ml-2"}
                                                color={order === i ? COLOR.CISCO_BLUE : COLOR.GREY}
                                                size="13px" />
                                        }
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                        {packages.map((row, i) => {
                            let weight_uom = WEIGHT_UNITS.filter(x => x.code === row.weight_uom);
                            weight_uom = weight_uom.length ? weight_uom : WEIGHT_UNITS[0];
                            let dim_uom = LENGTH_UNITS.filter(x => x.code === row.dim_uom);
                            dim_uom = dim_uom.length ? dim_uom : LENGTH_UNITS[0];
                            return (
                                <tr key={`option-${i}`} className="packagesRow">
                                    <td><div className="td boldType text-left p-0">
                                        {row.rma_num}
                                    </div></td>
                                    <td><div className="td">
                                        <POWRIcon
                                            className="fas fa-box mr-2"
                                            color={COLOR.DARK_GREY_1}
                                            size="14px" />
                                        {i + 1}
                                    </div></td>
                                    <td><div className="td text-left">
                                        {row.tn}
                                    </div></td>
                                    <td><div className="td">
                                        {`${parseFloat(row.weight).toFixed(2)} ${weight_uom[0].text}`}
                                    </div></td>
                                    <td><div className="td">
                                        {`${parseFloat(row.depth).toFixed(2)} x ${parseFloat(row.width).toFixed(2)} x ${parseFloat(row.height).toFixed(2)} ${dim_uom[0].text}`}
                                    </div></td>
                                    <td><div className="td pl-1">
                                        1
                                    </div></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="d-block d-xl-none py-16">
                    {packages.map((row, i) => {
                        let weight_uom = WEIGHT_UNITS.filter(x => x.code === row.weight_uom);
                        weight_uom = weight_uom.length ? weight_uom : WEIGHT_UNITS[0];
                        let dim_uom = LENGTH_UNITS.filter(x => x.code === row.dim_uom);
                        dim_uom = dim_uom.length ? dim_uom : LENGTH_UNITS[0];
                        return (
                            <div key={`success-package-${i}`} className="border-bottom-gray pb-3 mb-3">
                                <div className="row border-bottom-gray py-2">
                                    <div className="col d-flex">
                                        <POWRIcon
                                            className="fal fa-box mr-2"
                                            size="14px"
                                            color={COLOR.CISCO_BLUE}
                                        />
                                        <label className="strong m-0">{`${translate('title.package')}: ${i + 1}`}</label>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-12 d-flex">
                                        <label className="mr-1">{`${translate('gridColumns.tracking')}: `}</label>
                                        <label className="strong">{row.tn}</label>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <label className="mr-1">{`${translate('title.rma')}: `}</label>
                                        <label className="strong">
                                            {row.rma_num}
                                        </label>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <label className="mr-1">{`${translate('gridColumns.dimensions')}: `}</label>
                                        <label className="strong">
                                            {`${parseFloat(row.depth).toFixed(2)} x ${parseFloat(row.height).toFixed(2)} x ${parseFloat(row.width).toFixed(2)} ${dim_uom[0].text}`}
                                        </label>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <label className="mr-1">{`${translate('title.weight')}: `}</label>
                                        <label className="strong">
                                            {`${parseFloat(row.weight).toFixed(2)} ${weight_uom[0].text}`}
                                        </label>
                                    </div>
                                    <div className="col-12 d-flex">
                                        <label className="mr-1">{`${translate('gridColumns.qty')}: `}</label>
                                        <label className="strong">{1}</label>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </>);
}

export default SuccessReturn;